import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';
import LoadingIndicator from 'components/shared/loading-indicator';

enum NewsletterState {
  initial = "initial",
  submitting = "submitting",
  error = "error",
  success = "success",
}

type NewsLetterComponentsProps = {
  newsletterState: NewsletterState;
}

const NewsletterInput: React.FC = () => {
  return (
    <React.Fragment>
      <label className="newsletter-form__label" htmlFor="newsletter-form__email">
        Email Address:
      </label>
      <input
        className="newsletter-form__input"
        type="email"
        id="newsletter-form__email"
        name="emailAddress"
        placeholder="e.g. someone@example.com"
        required={true}
      />
    </React.Fragment>
  );
}

const NewsletterButton: React.FC<NewsLetterComponentsProps> = (props) => {
  const { newsletterState } = props;

  const isSubmitting = newsletterState === NewsletterState.submitting;

  return (
    <button type="submit" className="newsletter-form__btn btn btn--fill--blue" disabled={isSubmitting}>
      {isSubmitting
        ? <LoadingIndicator addClass="newsletter__loading-indicator"/>
        : `Sign Up`
      }
    </button>
  );
}

const NewsletterMessage: React.FC<NewsLetterComponentsProps> = (props) => {
  const { newsletterState } = props;

  const message =
    newsletterState === NewsletterState.success
    ? `Thank you for signing up!`
    : `Something went wrong. Please try again later.`;

  return (
    <p className="newsletter-form__message">{message}</p>
  )
}

type NewsletterFormProps = {
  formId: string;
  iterableEnabled: boolean;
}

const NewsletterForm: React.FC<NewsletterFormProps> = (props) => {
  const [newsletterState, setNewsletterState] = useState(NewsletterState.initial);
  const { formId } = props;

  // Side Effect - making the whole HTML component visible if this is loaded
  useEffect(() => {
    document.querySelector('.newsletter').removeAttribute(`hidden`);
  }, []);

  const handleIterableSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      setNewsletterState(NewsletterState.submitting)

      const url = '/data/newsletter/signup'
      const method = 'POST';
      const data = new FormData(event.target as HTMLFormElement);
      const csrfToken = Cookies.get('csrftoken')

      const options = {
        method: method,
        body: data,
        headers: {
          'X-CSRFToken': csrfToken
        }
      };

      fetch(url, options)
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          setNewsletterState(NewsletterState.success);
        })
        .catch(() => {
          setNewsletterState(NewsletterState.error);
        });
    },
    [setNewsletterState]
  )

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      setNewsletterState(NewsletterState.submitting)

      const url = 'https://s2143.t.eloqua.com/e/f2';
      const method = `POST`;
      const data = new FormData(event.target as HTMLFormElement);

      const options = {
        method: method,
        body: data
      };

      fetch(url, options)
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          setNewsletterState(NewsletterState.success);
        })
        .catch(() => {
          setNewsletterState(NewsletterState.error);
        });
    },
    [setNewsletterState]
  )

  if(props.iterableEnabled) {
    let formMessageChannel = '123011'

    if(formId === 'drama') {
      formMessageChannel = '123013'
    } else if(formId === 'climate') {
      formMessageChannel = '123014'
    } else if (formId === 'civics') {
      formMessageChannel = '123374'
    }

    const showInput = newsletterState === NewsletterState.initial || newsletterState === NewsletterState.submitting;

    return (
      <form className="newsletter__inner-form" name={formMessageChannel} method="post" id="newsletterSignupForm" onSubmit={handleIterableSubmit}>
        <input type="hidden" name="formName" value={formMessageChannel} />
        {showInput
          ? <React.Fragment>
              <NewsletterInput/>
              <NewsletterButton newsletterState={newsletterState} />
            </React.Fragment>
          : <NewsletterMessage newsletterState={newsletterState} />
        }
      </form>
    )
  } else {
    // The default, general newsletter
    let eloquaFormName = 'copyOfMASTERNewsletterSignUpPageWITH-1522637457526';

    // RWEB-7900 topic specific newsletters
    if (formId === 'drama') {
      eloquaFormName = 'UntitledForm-1666196377419';
    } else if (formId === 'climate') {
      eloquaFormName = 'UntitledForm-1680097972315';
    } else if (formId === 'civics') {
      eloquaFormName = 'copyOfClimateNewsletterSignUpForm-1699382117914';
    }

    const eloquaSiteID = '2143';

    const showInput = newsletterState === NewsletterState.initial || newsletterState === NewsletterState.submitting;

    return (
      <form className="newsletter__inner-form" name={eloquaFormName} method="post" id="newsletterSignupForm" onSubmit={handleSubmit}>
        <input type="hidden" name="elqFormName" value={eloquaFormName} />
        <input type="hidden" name="elqSiteID" value={eloquaSiteID} />
        <input type="hidden" name="Previews" value="on" checked={true} readOnly={true} />
        {showInput
          ? <React.Fragment>
              <NewsletterInput/>
              <NewsletterButton newsletterState={newsletterState} />
            </React.Fragment>
          : <NewsletterMessage newsletterState={newsletterState} />
        }
      </form>
    )
  }
}

/**
 * Initializes component.
 * @returns {function} init
 */
const init = (): void => {
  const formPlaceholder = document.querySelector('.newsletter-form-placeholder') as HTMLDivElement;

  // we need to check that the placeholder is there
  if (formPlaceholder) {
    const formId = formPlaceholder.dataset.formid;
    const iterableEnabled = formPlaceholder.dataset.iterableEnabled === 'True'
    const root = createRoot(formPlaceholder!);
    root.render(
      <NewsletterForm formId={formId} iterableEnabled={iterableEnabled} />
    );
  }
};

export { init, NewsletterForm };
