import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import 'whatwg-fetch';

import LoadingIndicator from 'components/shared/loading-indicator';
import ShowPoster from 'components/shared/react-show-poster';
import { Show } from 'components/shared/content-services-types';
import { ReactComponent as TitleCaretRight } from 'svg/pbs-right-caret.svg';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { PBS_config } from 'scripts/modules/pbs-config';
import { customLogMsg } from 'scripts/utils/customLogMsg';

interface ReactShowRowProps {
  collectionId: string;
  userId: string;
  title: string;
  seeAllUrl?: string;
  ctaButton?: CtaButton;
}

interface CtaButton {
  text: string;
  url: string;
}

// Sends request for show row data and returns JSON
// (RequestInfo and Response are types defined by whatwg-fetch)
async function fetchData(request: RequestInfo) {
  const response = await fetch(request, {
    method: 'GET',
  })
  .then((response) => {
    if (!response.ok) {
      customLogMsg(`pbsorg/React show row error on fetch of ${request}`)
      throw new Error(response.status.toString())
    } else {
      return response
    }
  })

  return await response.json();
}

// RWEB-7824: This is a special function used for the Recommendation Engine
// test specifically. This should not be in use for other instances of
// React Show Row, if/when those ever exist.
const posterClickHandler = (showId: string, userId: string, recommendationId: string) => {
  // When a user clicks a poster, make a POST request to the CS rec engine endpoint
  // tracking the user ID, recommendation ID, and show CID
  // Note: this is an internal API to shield CS endpoint creds from LastPass (RWEB-7906)
  // RWEB-8000 update: Molly confirms these click events can be sent to the same CS endpoint
  // for both the Recommended Dramas and Recommended Shows rows. The recommendation ID
  // provides distinguishing information.
  const apiEndpoint = `/personal/rec-engine-events/`;
  const data = {
    "recommendation_id": recommendationId,
    "events": [
      {
        "item_id": showId,
        "event_type": "click",
      }
    ]
  };

  fetch(apiEndpoint, {
    method: 'POST',
    keepalive: true, // need this to avoid interrupting call when user navigates off page
    body: JSON.stringify(data),
  }).catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
}

// Takes an array of shows from a CS response and
// returns an array of show poster React component Splide slides
const createPosters = (shows: Array<Show>, userId?: string, recommendationId?: string): Array<React.ReactElement> => {
  return shows.map((show: Show, index: number) => {
    const formattedShow = {
      __typename: show.item_type,
      cid: show.cid,
      description: show.description_short,
      image: show.images['show-poster2x3'],
      title: show.title,
      slug: show.slug
    }
    // only attach poster click handler if user ID and recommendation ID are provided
    const shouldSendClickEvent = userId && recommendationId;
    return (
      <SplideSlide
        key={show.cid}
        onClick={
          shouldSendClickEvent ?
          () => posterClickHandler(show.cid, userId, recommendationId)
          // eslint-disable-next-line
          : () => {}
        }
      >
        <ShowPoster
          show={formattedShow}
          gtmPosition={index + 1} // position should start at 1 not 0
        />
      </SplideSlide>
    );
  });
}

const ReactShowRow: React.FC<ReactShowRowProps> = (props) => {
  const { collectionId, userId, title, seeAllUrl, ctaButton } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [items, setItems] = useState([]);
  const titleLink = seeAllUrl ? seeAllUrl : '/shows/';

  // Fetch show row data from CS collection endpoint
  useEffect(() => {
    setIsFetching(true);
    // This fetch call is made to our internal API, not directly to CS, due to RWEB-7906.
    // Possible collectionIds:
    // 'recommended-shows' (original Rec Engine show row)
    // 'recommended-dramas' (Rec Engine powered show row for Explore Drama page - RWEB-8000)
    // These slugs correspond to the actual collection slugs in CS; our internal API matches them.
    const apiEndpoint = `/personal/${collectionId}/`;
    fetchData(apiEndpoint).then(response => {
      const shows = response?.collections[0]?.content;
      // RWEB-7978 if no content is returned, raise an error and don't render the component at all
      if (!shows || (shows && shows.length === 0)) {
        customLogMsg(`pbsorg/Recieved empty recommendation list from CS for collection: ${collectionId}`)
        setIsError(true);
      }
      customLogMsg(`pbsorg/Successfully fetched recommendation list from CS for collection: ${collectionId}`)
      const recommendationId = response?.collections[0]?.display_metadata?.recommendation_id;
      const showPosters = createPosters(shows, userId, recommendationId);
      setItems(showPosters);
      setIsFetching(false);
    }).catch((err) => {
      setIsError(true);
      // eslint-disable-next-line no-console
      console.error('Error fetching data: ', err)
    });
  }, [collectionId, userId]);

  if (isError) return null;

  return (
    <div className="react-show-row show-row theme-lightest-darkest">
      <div className="show-row__header">
        <h2 className="content-section-title">
          <a
            href={titleLink}
            className="content-section-title__link"
          >
            <span>{title}</span>
            <TitleCaretRight />
          </a>
        </h2>
        {ctaButton && (
          <a href={ctaButton.url} className="show-row__learn-more btn btn--fill--blue">
            {ctaButton.text}
          </a>
        )}
      </div>
      {isFetching ? (
        <LoadingIndicator addClass="react-show-row__loading-indicator" />
      ) : (
        <Splide
          options={{
            arrowPath: arrowPath, // using our custom caret SVG in splide's built-in arrows
            breakpoints: {
              [PBS_config.breakpoints.splide.md]: {
                perPage: 5,
              },
              [PBS_config.breakpoints.splide.sm]: {
                perPage: 4,
              },
              [PBS_config.breakpoints.splide.smaller]: {
                perPage: 3,
              },
            },
            classes: {
              arrow: 'carousel__arrow',
              prev: 'splide__arrow--prev carousel-prev',
              next: `splide__arrow--next carousel-next`,
            },
            drag: true,
            gap: '8px',
            pagination: false,
            perPage: 6,
            rewind: false,
            slideFocus: false,
          }}
        >
          {items}
        </Splide>
      )}
    </div>
  );
}

const init = (
  mountPointId: string,
  collectionId: string,
  userId: string,
  title: string,
  seeAllUrl?: string,
  ctaButton?: CtaButton): void => {

  // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById(mountPointId);
  if (mountPoint) {
    const root = createRoot(mountPoint!);
    root.render(
      <ReactShowRow
        collectionId={collectionId}
        userId={userId}
        title={title}
        seeAllUrl={seeAllUrl}
        ctaButton={ctaButton}
      />
    );
  }
};

export { init };
