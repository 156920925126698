import FallbackImage from 'scripts/modules/fallback-image';
import { init as videoSummariesInit } from 'components/video/summary/video';

interface CacheExpectations {
  videoSections?: NodeListOf<Element>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.videoSections = document.querySelectorAll('.video-group');
};

/**
 * Checks if image is broken and creates a fallback.
 * @returns {function} checkIfImageIsBroken
 * @param {object} e - event
 * @param {element} img - specific HTML img element (optional)
 */
const checkIfImageIsBroken = (e: Event, img?: HTMLImageElement) => {
  const image = e ? e.target : img;

  if (image) {
    const fallbackImage = new FallbackImage({
      el: image as HTMLImageElement,
    });
    fallbackImage.init();

    // remove error listener
    image.removeEventListener('error', checkIfImageIsBroken);
  }
};

/**
 * Initializes fallbacks for individual videos
 */
const setupGridImages = () => {
  cache.videoSections.forEach((section) => {
    // init the video summaries (apply tooltips and popovers)
    videoSummariesInit(section);
    const videos = section.querySelectorAll('.video-summary');

    videos.forEach((video) => {
      const image = video.querySelector('.video-summary__image');

      // @note: we can use error event here because images are being lazyloaded
      image.addEventListener('error', checkIfImageIsBroken);
    });
  });
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (): void => {
  setupCache();
  setupGridImages();
};

export { init };
