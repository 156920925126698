import { init as scheduleInit } from 'components/schedule/schedule';
import { init as timezoneToggleInit } from 'components/timezone-toggle/timezone-toggle';

interface CacheExpectations {
  pageScheduleSchedule?: HTMLDivElement;
  pageScheduleTimezoneToggle?: HTMLDivElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} _setupCache
 */
const setupCache = () => {
  cache.pageScheduleSchedule = document.querySelector(
    '.page-schedule .schedule'
  );
  cache.pageScheduleTimezoneToggle = document.querySelector(
    '.page-schedule .timezone-toggle'
  );
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (): void => {
  setupCache();
  scheduleInit(cache.pageScheduleSchedule);
  timezoneToggleInit(cache.pageScheduleTimezoneToggle);
};

export { init };
