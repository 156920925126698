interface CacheExpectations {
  showcaseThumbnails?: NodeListOf<HTMLLabelElement>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.showcaseThumbnails = document.querySelectorAll(
    '.showcase__thumbnail'
  );
};

const hoverTargetPreload = (e:Event) => {
  const hoverTargetClass = `.${(<HTMLLabelElement>e.target).getAttribute('data-hover-target')}`;
  const hoverTarget = document.querySelector(hoverTargetClass);
  // @TODO can we do anything with this?
  if (hoverTarget.classList.contains('lazyload')) {
    hoverTarget.classList.add('lazypreload');
  }
  e.target.removeEventListener('mouseover', hoverTargetPreload)
}

const addEvents = () => {
  cache.showcaseThumbnails.forEach((thumbnail) => {
    thumbnail.addEventListener('mouseover', hoverTargetPreload);
    thumbnail.addEventListener('click', hoverTargetPreload);
  })
}

const init = (): void => {
  setupCache();
  addEvents();
}

export { init };
