import Cookies from 'js-cookie';
import { customEvents } from 'scripts/custom-events';
interface CacheExpectations {
  body?: HTMLBodyElement;
  badge?: HTMLDivElement;
  badgeLink?: HTMLLinkElement;
  badgeCloseBtn?: HTMLButtonElement;
  badgeCtaArrow?: HTMLSpanElement;
  badgeCtaDefaultText?: HTMLSpanElement;
  badgeCtaMemberText?: HTMLSpanElement;
  badgeGraphicMultiple?: HTMLSpanElement;
  badgeGraphicMultipleInstances?: NodeListOf<HTMLImageElement>;
  badgeTextIntro?: HTMLSpanElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = (): void  => {
  cache.body = document.querySelector('body');
  cache.badge = document.querySelector('.badge');
  cache.badgeLink = document.querySelector('.badge .badge__link');
  cache.badgeCloseBtn = document.querySelector('.badge .badge__close-btn');
  cache.badgeCtaArrow = document.querySelector('.badge .badge__arrow');
  cache.badgeCtaDefaultText = document.querySelector('.badge .badge__arrow__text--default');
  cache.badgeCtaMemberText = document.querySelector('.badge .badge__arrow__text--member');
  cache.badgeGraphicMultiple = document.querySelector('.badge .badge__graphic--multiple');
  cache.badgeGraphicMultipleInstances = document.querySelectorAll('.badge__graphic--multiple__instance');
  cache.badgeTextIntro = document.querySelector('.badge__text__intro');
};

/**
 * Toggles text of cta arrow, displays arrow
 * @param {string} badgeTextKind - `default` | `member`
 * @returns {void}
*/
const showArrowText = (badgeTextKind: `default` | `member`): void => {
  if (badgeTextKind === `member`) {
    cache.badgeCtaMemberText?.classList.remove(`is-hidden`);
  } else {
    cache.badgeCtaDefaultText?.classList.remove(`is-hidden`);
  }
  cache.badgeCtaArrow.classList.remove(`is-invisible`);
}

/**
 * Updates the badge link, based on the 'data-passport-member-link' attribute
 */
const updateBadgeLink = (): void  => {
  let url = null;
  if (cache.badgeGraphicMultiple) {
    cache.badgeGraphicMultipleInstances.forEach((instance) => {
      // find the first instace of an image that isn't hidden, and use that
      // image's data attribute as the URL
      if (instance.hidden === false) {
        url = instance.getAttribute('data-passport-member-link');
      }
    });
  } else {
    url = cache.badgeLink.getAttribute('data-passport-member-link');
  }

  // if the data attribute exists, and it isn't the current href
  if (url && cache.badgeLink.getAttribute('href') !== url) {
    cache.badgeLink.setAttribute('href', url);
  }
};

/**
 * Orchestration function for when the user is logged in and is passport
 */
const onUserIsPassport = () => {
  updateBadgeLink();
  showArrowText(`member`);
  removeEvents();
}

/**
 * Orchestration function for when the user is not logged in, or is logged in and
 * not passport
 */
const onUserIsDefault = () => {
  showArrowText(`default`);
  removeEvents();
}

/**
 * If there are multiple graphic instances, this chooses one randomly
 * and removes the `hidden` attribute
 */
const makeOneGraphicVisible = (): void => {
  const badgeGraphicInstances = cache.badgeGraphicMultiple.querySelectorAll('.badge__graphic--multiple__instance');
  const numberOfInstances = badgeGraphicInstances.length;
  const randomGraphicInstance = badgeGraphicInstances[Math.floor(Math.random() * numberOfInstances)] as HTMLImageElement;
  randomGraphicInstance.hidden = false;
  const textToDisplay = randomGraphicInstance.dataset.introText;
  if (textToDisplay && cache.badgeTextIntro) {
    cache.badgeTextIntro.textContent = textToDisplay;
  }
}

/**
 * Sets up badge visibility, body layout
 */
const setupBadge = (): void => {
  if (cache.badgeGraphicMultiple) {
    makeOneGraphicVisible();
  }
  cache.badge.classList.remove('is-hidden');
  cache.badge.classList.add('is-visible');
};

/**
 * Handles the close button click on the badge
 */
const onBadgeCloseBtnClick = (): void => {
  // hide the badge, set a cookie so it doesn't appear again for a set period of time
  // defaults to 7 days (which is typical for the case of show badges)
  const hideBadgeForThisManyDays = parseInt(cache.badge.dataset.closeDuration) || 7;

  cache.badge.classList.add('is-collapsed');
  Cookies.set('pbs.hideBadge', 'true', {
    expires: hideBadgeForThisManyDays,
    secure: true,
    sameSite: 'Lax',
  });
};

/**
 * Removes event listeners.
 */
const removeEvents = (): void => {
  window.removeEventListener(customEvents.userIsPassport, onUserIsPassport);
  window.removeEventListener(customEvents.userIsNotPassport, onUserIsDefault);
  window.removeEventListener(customEvents.userIsPassport, onUserIsDefault);
}

/**
 * Adds event listeners.
 */
const addEvents = (): void => {
  cache.badgeCloseBtn.addEventListener('click', onBadgeCloseBtnClick);
  // these custom events are emitted when the user data is loaded
  window.addEventListener(customEvents.userIsPassport, onUserIsPassport);
  window.addEventListener(customEvents.userIsNotPassport, onUserIsDefault);
  window.addEventListener(customEvents.userIsNotLoggedIn, onUserIsDefault);
};

/**
 * Initializes.
 */
const init = (): void => {
  setupCache();

  // only do this stuff if there *is* a badge
  if (cache.badge) {
    // badge hidden state is dictated by the pbs.hideBadge cookie
    const badgeIsNotHidden = Cookies.get('pbs.hideBadge') ? false : true;

    if (badgeIsNotHidden) {
      setupBadge();
      addEvents();
    }
  }
};

export { init };
