interface FallbackImageOptions {
  el?: HTMLImageElement;
  textFallback?: boolean;
}

/**
 * Class for handling fallback image logic.
 */
const FallbackImage = class FallbackImage {
  el: HTMLImageElement;
  textFallback: boolean;
  parentNode: ParentNode;

  /**
   * Initializes class.
   * @param {object} options - custom settings passed in (optional)
   * @constructor
   */
  constructor(options: FallbackImageOptions) {
    if (options) {
      this.setupProps(options);
    }
  }

  /**
   * Sets up default properties.
   * @param {object} options - custom settings passed in (optional)
   */
  setupProps(options: FallbackImageOptions): void {
    this.el = options.el as HTMLImageElement;
    // being more verbose with this check because using nullish coalescing
    //  operator (aka ??) was causing a sentry error
    this.textFallback = options.textFallback ? options.textFallback : false;
  }

  /**
   * Removes any nodes with broken paths within picture elements.
   */
  updateNodes(): void {
    const parent = this.parentNode as HTMLElement || this.el.parentNode as HTMLElement;
    const firstChild = parent.firstChild as HTMLElement;

    if (!parent) {
      return;
    }

    const count = parent.childElementCount - 1;

    if (parent.tagName.toLowerCase() === 'picture') {
      for (let i = 0; i < count; i += 1) {
        parent.removeChild(firstChild);
      }
    }

    if (this.textFallback) {
      parent.classList.add('fallback-image__text-container');
      const fallbackDiv = document.createElement('div');
      fallbackDiv.classList.add('fallback-image__text');
      fallbackDiv.innerHTML = this.el.alt;

      firstChild.style.display = 'none';
      parent.appendChild(fallbackDiv);
    }
  }

  /**
   * Removes responsive attributes to prevent future calls.
   */
  removeResponsiveAttributes(): void {
    if (this.el.hasAttribute('srcset')) {
      this.el.setAttribute('srcset', '/');
    }

    if (this.el.hasAttribute('sizes')) {
      this.el.removeAttribute('sizes');
    }
  }

  /**
   * Update image elements for fallback styling.
   */
  init(): void {
    if (this.el) {
      // update notes for picture elements
      this.removeResponsiveAttributes();
      this.updateNodes();
    }
  }
};

export default FallbackImage;
