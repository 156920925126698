/*
  <LoadingIndicator />
  Loading animation is handled in CSS - see /sass/global/_loading-indicator.scss
*/

import React from 'react';


interface LoadingIndicatorProps {
  addClass?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  const { addClass } = props;

  let classNames = 'loading-indicator';

  if (addClass) {
    classNames += ` ${addClass}`;
  }

  return (
    <div className={classNames} data-testid="loading-indicator">
      <div className="loading-indicator__spinner">
        <div className="loading-indicator__spinner bounce1"></div>
        <div className="loading-indicator__spinner bounce2"></div>
        <div className="loading-indicator__spinner bounce3"></div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
