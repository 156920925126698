const footer: HTMLElement = document.querySelector('footer');

const onIntersection = (entries: IntersectionObserverEntry[]) => {
  entries.forEach(entry => {
      if (entry.isIntersecting) {
        footer.classList.add('is-in-viewport');
        observer.unobserve(footer);
      }
    }
  )
}

const observer = new IntersectionObserver(onIntersection, {
  rootMargin: '50px',
  threshold: .1
});

observer.observe(footer);

export {};