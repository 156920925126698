import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'scripts/modules/external-links';
import 'components/page-footer/page-footer';
import { init as adsInit } from 'components/ad/ads';
import { init as badgeInit } from 'components/badge/badge';
import { init as showcaseInit } from 'components/showcase/showcase';
import { init as showRowCarouselInit } from 'components/show-row/show-row';
import { init as reactShowRowCarouselInit } from 'components/show-row/react-show-row/react-show-row';
import 'components/page-header/js/page-header';
import { init as myListButtonInit } from 'components/my-list/my-list-button/components/button-group';
import { init as newsLetterInit } from 'components/newsletter/newsletter'
import { init as stationInit } from 'components/station/station';
import { init as videoGroupInit } from 'components/video/video-group';
import { init as pageScheduleInit } from 'components/page-schedule/page-schedule';
import { init as swapPromoInit } from 'scripts/modules/swap-promos';
import { init as localizationEventsInit } from 'scripts/modules/localization-events';
import { init as getUserViewingHistoryInit } from 'scripts/modules/get-user-viewing-history';
import { init as viewingHistoryProgressInit } from 'components/viewing-history-progress/viewing-history-progress';
import { getUserId } from 'scripts/utils/getUserId';

// RWEB-7834 limited time test for adding Rec Engine show row to home page
const recEngineShowRowInit = (): void => {
  const userId = getUserId();
  const mountPointId = 'recommendation-engine-show-row';
  // only render the Rec Engine row if user is signed in and the feature flag is set to True
  // (i.e. the mountPoint element exists)
  const mountPoint = document.getElementById(mountPointId);
  if (userId && mountPoint) {
    // initialize the React show row powered by the Recommendation Engine
    const collectionId = 'recommended-shows';
    const title = 'Top Picks for You';
    const ctaButton = {
      text: 'Explore More For You',
      url: '/explore/'
    }
    reactShowRowCarouselInit(mountPointId, collectionId, userId, title, null, ctaButton);
  }
}

adsInit();
badgeInit();
showcaseInit();
recEngineShowRowInit();
showRowCarouselInit();
myListButtonInit();
newsLetterInit();
stationInit();
videoGroupInit();
pageScheduleInit();
swapPromoInit();
localizationEventsInit();
getUserViewingHistoryInit();
viewingHistoryProgressInit();
