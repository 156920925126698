import React, { forwardRef, RefObject, useState, useEffect } from 'react';

import ImageWithFallback from 'components/shared/react-image-with-fallback';
import { ShowRowPreviewContainer } from 'components/show-row/show-row-preview/components/container';
import { Genre } from 'components/shared/content-services-types';

interface Show {
  cid: string;
  description?: string;
  genre?: Genre | string[];
  image?: string;
  title: string;
  slug: string;
  website?: string;
  __typename: string;
}

interface ShowPosterProps {
  show: Show;
  gtmPosition: number;
}

const showUrl = (show: Show) => {
  const leadingUrlSlug = show.__typename.toLowerCase() === "show" ? "show" : "franchise";
  return `/${leadingUrlSlug}/${show.slug}/`;
}

const ShowPoster = forwardRef((props: ShowPosterProps, ref: RefObject<HTMLElement>) => {
  const { show, gtmPosition } = props;
  const [hasBrokenImage, setHasBrokenImage] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [articleClasses, setArticleClasses] = useState('react-show-poster__item show-poster');
  const shouldShowPreviewButton = show.__typename.toLowerCase() === 'show' && isFetched;

  const handleHover = () => {
    // Add a slight delay before reacting to poster hover;
    // This is so that we're not just making a million calls as the user's mouse moves over the grid.
    // They should indicate at least a momentary intention of checking out the show.
    setTimeout(() => {
      // if we have not already fetched additional data for this show poster, fetch it
      if (!isFetched) {
        setIsFetched(true);
      }
    }, 500);
  }

  useEffect(() => {
    if (hasBrokenImage) {
      setArticleClasses(`${articleClasses} fallback-image__container fallback-image__container--blue`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBrokenImage]);

  useEffect(() => {
    if (isFetched) {
      setArticleClasses(`${articleClasses} fetched-data`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  return (
    <article
      key={show.cid}
      className={articleClasses}
      data-testid="cy-react-show-poster-item"
      ref={ref}
      onMouseOver={handleHover}
    >
      <a
        className="react-show-poster__link-container"
        href={showUrl(show)}
        data-gtm-label={show.title}
        data-gtm-position={gtmPosition}
      >
        <ImageWithFallback
          customClass="react-show-poster__content"
          isBroken={hasBrokenImage}
          onBrokenImage={setHasBrokenImage}
          textOverlay={true}
          path={show.image}
          crop="?format=auto&crop=200x300"
          title={show.title}
        />
      </a>
      {shouldShowPreviewButton && (
        <div className="show-row__preview-btn-placeholder">
          <ShowRowPreviewContainer
            title={show.title}
            showSlug={show.slug}
          />
        </div>
      )}
    </article>
  );
});

export default ShowPoster;